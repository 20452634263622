import { useState } from "react";

import { Variant } from "@stesvis/react-core";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import clsx from "clsx";
import { Col, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";

import { Card, Widget } from "[root]/src/components/molecules";
import { useServices } from "[root]/src/services";
import { Button } from "../../atoms";
import { useMembershipApi } from "./useMembershipApi";
import { usePaymentsApi } from "./usePaymentsApi";

type SubscriptionFormProps = {
  intentSecret: string;
  processing?: boolean;
};

export const SubscriptionForm = ({
  intentSecret,
  processing,
  ...props
}: SubscriptionFormProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const services = useServices();

  const { membershipProcessApiMutation } = useMembershipApi();
  const paymentId = membershipProcessApiMutation.data?.data.id;

  const paymentsDetailsApi = usePaymentsApi().paymentsDetailsApiHook({
    id: Number(paymentId),
    enabled: !!paymentId && paymentId > 0,
  });
  const paymentsDetails = paymentsDetailsApi.data?.data;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!stripe || !elements) return;

      setIsProcessing(true);

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        services.utilities.toast({
          variant: Variant.error,
          message: submitError.message || "Submit failed",
        });
        return;
      }

      services.utilities.toast({
        variant: Variant.info,
        message: "Processing payment...",
      });

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret: intentSecret,
        confirmParams: {
          return_url: `${process.env.REACT_APP_FRONTEND_URL}/membership/success`,
        },
        redirect: "if_required",
      });

      if (error) {
        return services.utilities.toast({
          variant: Variant.error,
          message: error.message || "Payment failed",
        });
      }

      await membershipProcessApiMutation.mutateAsync({
        paymentIntentId: paymentIntent.id,
      });

      setSuccess(true);
      services.utilities.toast({
        variant: Variant.success,
        message: "Payment successful! 🎉",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  if (success) {
    return (
      <Widget
        title="Payment successful! 🎉"
        IconComponent={FaCheck}
        variant={Variant.success}
      >
        <p>You are now enrolled with a full year membership.</p>
        {!!paymentsDetails && (
          <>
            <p>
              <b>Amount</b>:{" "}
              {services.utilities.formatNumber(
                (paymentsDetails.amount || 0) / 100,
                2
              )}{" "}
              {paymentsDetails?.currency}
            </p>

            <p>
              <b>Start Date</b>:{" "}
              {services.utilities.formatDate(
                paymentsDetails?.subscriptionStartDate
              )}
            </p>
            <p>
              <b>Expiry Date</b>:{" "}
              {services.utilities.formatDate(
                paymentsDetails?.subscriptionEndDate
              )}
            </p>
          </>
        )}
        <Button
          variant={Variant.tertiary}
          onClick={() =>
            window.open(
              membershipProcessApiMutation.data?.data.invoiceUrl,
              "_blank"
            )
          }
        >
          View Invoice
        </Button>
      </Widget>
    );
  }

  return (
    <Card>
      <Card.Body>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              {true && (
                <div className={clsx("mb-3 d-block")}>
                  <PaymentElement
                    options={{
                      layout: "tabs",
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button
                type="submit"
                disabled={!stripe || isProcessing}
                loading={isProcessing}
                variant={Variant.primary}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </form>
      </Card.Body>
    </Card>
  );
};
