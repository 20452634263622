import { useEffect, useState } from "react";

import { Variant } from "@stesvis/react-core";
import { loadStripe } from "@stripe/stripe-js";

import { FilterQueryParams, Payment } from "@stesvis/metagolf-apis";
import {
  DataTable,
  DataTablePaginationDataProps,
  DataTableSortProps,
  EmptyDataBox,
} from "[root]/src/components/organisms";
import { AuthenticatedPageTemplate } from "[root]/src/components/templates";
import { MGRoutes } from "[root]/src/lib";
import { useServices } from "[root]/src/services";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../atoms";
import { Card } from "../../molecules";
import { useMembershipApi } from "./useMembershipApi";
import { usePaymentsApi } from "./usePaymentsApi";
import { usePaymentsDataTable } from "./usePaymentsDataTable";

type MembershipPageProps = {};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

export const MembershipPage = (props: MembershipPageProps) => {
  const services = useServices();

  const { membershipCheckoutSessionApiMutation } = useMembershipApi();
  const { paymentsActiveSubscriptionApiHook, paymentsFilterApiHook } =
    usePaymentsApi();
  const paymentsActiveSubscriptionApi = paymentsActiveSubscriptionApiHook({
    enabled: true,
  });
  const activeSubscription = paymentsActiveSubscriptionApi.data?.data;

  const { columns } = usePaymentsDataTable({
    submitCancelPaymentCallback: cancelPayment,
  });

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [paginationData, setPaginationData] =
    useState<DataTablePaginationDataProps>({});
  const [sort, setSort] = useState<DataTableSortProps>();

  const paymentsFilterApi = paymentsFilterApiHook({
    currentPage,
    pageSize,
    searchValue,
    sort,
    enabled: true,
  });
  const payments = paymentsFilterApi.data?.data;

  useEffect(() => {
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      lastPage: payments?.pagesTotal,
      total: payments?.recordsTotal,
      to:
        currentPage * pageSize > (payments?.recordsTotal || 0)
          ? payments?.recordsTotal
          : currentPage * pageSize,
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments]);

  const handleSort = (
    column: FilterQueryParams["sortColumn"],
    direction: FilterQueryParams["sortDir"]
  ) => {
    setCurrentPage(1);
    setSort({ sortColumn: column, sortDirection: direction });
  };

  const handleRowsPerPageChange = (newPerPage: number) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // Call your backend to create the Checkout session.
    const {
      data: { sessionId },
    } = await membershipCheckoutSessionApiMutation.mutateAsync(undefined);
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    if (stripe === null)
      return services.utilities.toast({
        variant: Variant.error,
        message: "Stripe is null!",
      });

    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    if (error) {
      return services.utilities.toast({
        variant: Variant.error,
        message: error.message || "Payment failed",
      });
    }
  };

  return (
    <AuthenticatedPageTemplate
      titleProps={{
        breadcrumbsProps: [
          { title: "Dashboard", to: MGRoutes.dashboard },
          { title: "Membership", active: true },
        ],
        title: "Membership",
      }}
      loading={
        paymentsFilterApi.isLoading || paymentsActiveSubscriptionApi.isLoading
      }
    >
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** DATA TABLE **** */}
                <DataTable<Payment>
                  buttons={
                    !activeSubscription && (
                      <Button
                        variant={Variant.secondary}
                        navigate={MGRoutes.membership_subscribe}
                        // onClick={() => openCreateCourseModal()}
                      >
                        Subscribe
                      </Button>
                    )
                  }
                  className="dataTable table-no-more"
                  // dense
                  columns={columns}
                  //   conditionalRowStyles={conditionalRowStyles}
                  data={payments?.items || []}
                  EmptyDataComponent={<EmptyDataBox />}
                  onSearch={setSearchValue}
                  onSort={handleSort}
                  paginate
                  search
                  searchValue={searchValue}
                  sortColumn={sort?.sortColumn}
                  sortDirection={sort?.sortDirection}
                  paginationProps={
                    !!paginationData && {
                      from: paginationData.from,
                      lastPage: paginationData.lastPage,
                      page: currentPage,
                      pageSize: pageSize,
                      total: paginationData.total,
                      to: paginationData.to,
                      onPageChange: setCurrentPage,
                      onPageSizeChange: handleRowsPerPageChange,
                    }
                  }
                  // onRowClick={handleRowClick}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </AuthenticatedPageTemplate>
  );

  async function cancelPayment(id: number) {
    try {
      // await coursesDeleteApiMutation.mutateAsync({ id });
      return true;
    } catch (err) {
      return false;
    }
  }
};
