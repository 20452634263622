import {
  Course,
  DataResponse,
  Endpoints,
  FilterResponse,
  HoleGroup,
  Tee,
} from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";

import { DataTableSortProps } from "[root]/src/components/organisms";
import { useApiMutation, useApiQuery } from "[root]/src/hooks";
import { ApiBaseProps } from "[root]/src/lib";
import { useServices } from "[root]/src/services";

export const useCoursesApi = () => {
  const services = useServices();

  const coursesCreateApiMutation = useApiMutation<
    DataResponse<Course>,
    Error,
    Course
  >({
    mutationKey: [Endpoints.Courses.create],
    mutationFn: (data) => services.api.courses.create(data),
    onSuccess: (data) => {
      services.utilities.invalidateQuery([Endpoints.Courses.filter]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const coursesDeleteApiMutation = useApiMutation<
    DataResponse<string>,
    Error,
    { id: number }
  >({
    mutationKey: [Endpoints.Courses.delete],
    mutationFn: ({ id }) => services.api.courses.delete(id),
    onSuccess: (data) => {
      services.utilities.invalidateQuery([Endpoints.Courses.filter]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  type CoursesFilterProps = ApiBaseProps & {
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sort?: DataTableSortProps;
  };

  const useCoursesFilterApi = ({
    currentPage,
    pageSize,
    searchValue,
    sort,
    include,
    enabled,
  }: CoursesFilterProps) =>
    useApiQuery({
      queryKey: [
        Endpoints.Courses.filter,
        currentPage,
        pageSize,
        searchValue,
        sort?.sortColumn,
        sort?.sortDirection,
      ],
      queryFn: async (): Promise<FilterResponse<Course>> =>
        await services.api.courses.filter(
          {
            page: currentPage,
            pageSize: pageSize,
            searchValue: searchValue,
            sortColumn: sort?.sortColumn,
            sortDir: sort?.sortDirection,
          },
          include
        ),
      enabled: enabled,
    });

  const coursesUpdateApiMutation = useApiMutation<
    DataResponse<Course>,
    Error,
    Course
  >({
    mutationKey: [Endpoints.Courses.edit],
    mutationFn: (data) => services.api.courses.update(Number(data.id), data),
    onSuccess: (data) => {
      services.utilities.invalidateQuery([Endpoints.Courses.filter]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const coursesSaveHolesApiMutation = useApiMutation<
    DataResponse<Course>,
    Error,
    { id: number; holeGroup: HoleGroup }
  >({
    mutationKey: [Endpoints.Courses.saveHoles],
    mutationFn: (data) =>
      services.api.courses.saveHoles(Number(data.id), data.holeGroup),
    onSuccess: (data) => {
      services.utilities.invalidateQuery([Endpoints.Courses.get, data.data.id]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const coursesDeleteHolesApiMutation = useApiMutation<
    DataResponse<string>,
    Error,
    { id: number; holeGroupId: number }
  >({
    mutationKey: [Endpoints.Courses.deleteHoles],
    mutationFn: ({ id, holeGroupId }) =>
      services.api.courses.deleteHoles(id, holeGroupId),
    onSuccess: (data, args) => {
      services.utilities.invalidateQuery([Endpoints.Courses.get, args.id]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const coursesSaveTeeApiMutation = useApiMutation<
    DataResponse<Course>,
    Error,
    { courseId: number; tee: Tee }
  >({
    mutationKey: [Endpoints.Courses.saveTee],
    mutationFn: (data) =>
      services.api.courses.saveTee(Number(data.courseId), data.tee),
    onSuccess: (data) => {
      services.utilities.invalidateQuery([Endpoints.Courses.get, data.data.id]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const coursesDeleteTeeApiMutation = useApiMutation<
    DataResponse<string>,
    Error,
    { courseId: number; teeId: number }
  >({
    mutationKey: [Endpoints.Courses.deleteTee],
    mutationFn: ({ courseId: id, teeId }) =>
      services.api.courses.deleteTee(id, teeId),
    onSuccess: (data, args) => {
      services.utilities.invalidateQuery([
        Endpoints.Courses.get,
        args.courseId,
      ]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  return {
    coursesCreateApiMutation,
    coursesDeleteApiMutation,
    coursesFilterApi: useCoursesFilterApi,
    coursesUpdateApiMutation,
    coursesSaveHolesApiMutation,
    coursesDeleteHolesApiMutation,
    coursesSaveTeeApiMutation,
    coursesDeleteTeeApiMutation,
  };
};
