import { Variant } from "@stesvis/react-core";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { ComponentBaseProps } from "[root]/src/lib";

export type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "size"
> &
  ComponentBaseProps & {
    disabled?: boolean;
    loading?: boolean;
    icon?: string;
    navigate?: string;
    size?: "sm" | "lg";
  };

export const Button = ({
  children,
  className = "",
  variant = Variant.primary,
  disabled = false,
  loading,
  icon = "fa fa-spinner fa-spin me-2",
  onClick,
  navigate: to,
  type = "button",
  size,
  ...props
}: ButtonProps) => {
  const navigate = useNavigate();

  disabled = disabled ?? loading;
  const buttonVariant = variant === Variant.error ? "danger" : variant;
  const buttonClassName = clsx([
    "btn text-white",
    variant && `btn-${buttonVariant}`,
    size && `btn-${size}`,
    disabled && "opacity-50",
    className,
  ]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick || to) event.preventDefault();
    if (onClick) onClick?.(event);
    if (to) navigate(to);
  };

  return (
    <button
      className={buttonClassName}
      disabled={disabled}
      onClick={handleClick}
      type={type}
      {...props}
    >
      {loading ? <i className={clsx(icon, "!m-0")} /> : children}
    </button>
  );
};
