import {
  DataResponse,
  Endpoints,
  FilterResponse,
  Payment,
} from "@stesvis/metagolf-apis";

import { DataTableSortProps } from "[root]/src/components/organisms";
import { useApiQuery } from "[root]/src/hooks";
import { ApiBaseProps } from "[root]/src/lib";
import { useServices } from "[root]/src/services";

export const usePaymentsApi = () => {
  const services = useServices();

  type PaymentsActiveSubscriptionApiProps = ApiBaseProps;

  const usePaymentsActiveSubscriptionApi = ({
    enabled,
  }: PaymentsActiveSubscriptionApiProps) =>
    useApiQuery({
      queryKey: [Endpoints.Payments.activeSubscription],
      queryFn: async (): Promise<DataResponse<Payment>> =>
        await services.api.payments.activeSubscription(),
      enabled: enabled,
    });

  type PaymentsDetailsApiProps = ApiBaseProps & {
    id: number;
  };

  const usePaymentsDetailsApi = ({
    id,
    include,
    enabled,
  }: PaymentsDetailsApiProps) =>
    useApiQuery({
      queryKey: [Endpoints.Payments.get, id],
      queryFn: async (): Promise<DataResponse<Payment>> =>
        await services.api.payments.get(id, include),
      enabled: enabled,
    });

  type PaymentsFilterProps = ApiBaseProps & {
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sort?: DataTableSortProps;
  };

  const usePaymentsFilterApi = ({
    currentPage,
    pageSize,
    searchValue,
    sort,
    include,
    enabled,
  }: PaymentsFilterProps) =>
    useApiQuery({
      queryKey: [
        Endpoints.Payments.filter,
        currentPage,
        pageSize,
        searchValue,
        sort?.sortColumn,
        sort?.sortDirection,
      ],
      queryFn: async (): Promise<FilterResponse<Payment>> =>
        await services.api.payments.filter(
          {
            page: currentPage,
            pageSize: pageSize,
            searchValue: searchValue,
            sortColumn: sort?.sortColumn,
            sortDir: sort?.sortDirection,
          },
          include
        ),
      enabled: enabled,
    });

  return {
    paymentsActiveSubscriptionApiHook: usePaymentsActiveSubscriptionApi,
    paymentsDetailsApiHook: usePaymentsDetailsApi,
    paymentsFilterApiHook: usePaymentsFilterApi,
  };
};
