import { Route, Routes, useLocation } from "react-router-dom";

import {
  DashboardPage,
  MembershipPage,
  MembershipSubscribePage,
  MembershipSuccessPage,
  NotFoundPage,
  SignInPage,
} from "[root]/src/components/pages";
import {
  GolfCourseDetailsPage,
  GolfCoursesPage,
} from "[root]/src/components/pages/golf-courses";
import { MGRoutes } from "[root]/src/lib";
import { NoAuth, RequireAuth } from "[root]/src/navigation";

export const NavigationContainer = () => {
  const location = useLocation();

  console.log("location", location);

  return (
    <Routes>
      {/* Golf Courses */}
      <Route
        path={MGRoutes.golfCourses}
        element={
          <RequireAuth>
            <GolfCoursesPage />
          </RequireAuth>
        }
      />
      <Route
        path={MGRoutes.golfCourses_details}
        element={
          <RequireAuth>
            <GolfCourseDetailsPage />
          </RequireAuth>
        }
      />

      {/* Membership */}
      <Route
        path={MGRoutes.membership}
        element={
          <RequireAuth>
            <MembershipPage />
          </RequireAuth>
        }
      />
      <Route
        path={MGRoutes.membership_subscribe}
        element={
          <RequireAuth>
            <MembershipSubscribePage />
          </RequireAuth>
        }
      />
      <Route
        path={MGRoutes.membership_success}
        element={
          <RequireAuth>
            <MembershipSuccessPage />
          </RequireAuth>
        }
      />

      {/* SignIn */}
      <Route
        path={MGRoutes.login}
        element={
          <NoAuth>
            <SignInPage />
          </NoAuth>
        }
      />

      {/* Dashboard */}
      <Route
        path={MGRoutes.dashboard}
        element={
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
