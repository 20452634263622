import { useServices } from "[root]/src/services";
import { Button } from "../atoms";

type PricingWidgetProps = {
  title: string;
  price: number;
  description?: string;
  hideCheckoutButton?: boolean;
  onCheckoutClick?: () => void;
};

export const PricingWidget = ({
  title,
  price,
  description,
  hideCheckoutButton,
  onCheckoutClick,
  ...props
}: PricingWidgetProps) => {
  const services = useServices();

  return (
    <div className="pricing-table row no-gutters mt-3 mb-3">
      <div className="col-xl-4 col-lg-6 col-sm-12 text-center">
        <div className="plan most-popular">
          <div className="plan-ribbon-wrapper">
            <div className="plan-ribbon">Popular</div>
          </div>
          <h3>
            {title}
            <span>${services.utilities.formatNumber(price, 2)}</span>
          </h3>
          {description && <p>{description}</p>}
          <Button
            className="mt-3"
            onClick={onCheckoutClick}
            disabled={hideCheckoutButton}
          >
            {hideCheckoutButton ? "You are already subscribed" : "Checkout"}
          </Button>
          <ul>
            <li>
              <strong>Unlimited</strong> Games
            </li>
            <li>
              <strong>Auto</strong> Renew
            </li>
            <li>
              <strong>12 Months</strong> Full Access
            </li>
            {/* <li>
              <strong>Unlimited</strong> subdomains
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};
