import { useEffect, useRef, useState } from "react";

import { Course, FilterQueryParams } from "@stesvis/metagolf-apis";
import { Col, Row } from "react-bootstrap";

import { Variant } from "@stesvis/react-core";
import { Button } from "[root]/src/components/atoms";
import { Card } from "[root]/src/components/molecules";
import {
  DataTable,
  DataTablePaginationDataProps,
  DataTableSortProps,
  EmptyDataBox,
} from "[root]/src/components/organisms/data-tables";
import { AuthenticatedPageTemplate } from "[root]/src/components/templates";
import { useUserInfo } from "[root]/src/hooks";
import { HandleForm, MGRoutes } from "[root]/src/lib";
import { Navigate, useNavigate } from "react-router-dom";
import { useCourseDataTable } from "./useCourseDataTable";
import { useCoursesApi } from "./useCoursesApi";

type GolfCoursesPageProps = {};

export const GolfCoursesPage = (props: GolfCoursesPageProps) => {
  const { isAdmin } = useUserInfo();

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [paginationData, setPaginationData] =
    useState<DataTablePaginationDataProps>({});
  const [sort, setSort] = useState<DataTableSortProps>();

  const golfCourseFormRef = useRef<HandleForm>(null);
  const {
    coursesCreateApiMutation,
    coursesDeleteApiMutation,
    coursesUpdateApiMutation,
    coursesFilterApi: coursesFilterApiHook,
  } = useCoursesApi();
  const coursesFilterApi = coursesFilterApiHook({
    currentPage,
    pageSize,
    searchValue,
    sort,
    include: "Location",
    enabled: true,
  });
  const courses = coursesFilterApi.data?.data;

  const navigate = useNavigate();

  const { columns, openCreateCourseModal } = useCourseDataTable({
    golfCourseFormRef,
    handleCreateCourseCallback: handleCreateCourse,
    submitCreateCourseCallback: submitCourse,
    handleEditCourseCallback: handleEditCourse,
    submitEditCourseCallback: submitCourse,
    submitDeleteCourseCallback: deleteCourse,
  });

  useEffect(() => {
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      lastPage: courses?.pagesTotal,
      total: courses?.recordsTotal,
      to:
        currentPage * pageSize > (courses?.recordsTotal || 0)
          ? courses?.recordsTotal
          : currentPage * pageSize,
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  const handleSort = (
    column: FilterQueryParams["sortColumn"],
    direction: FilterQueryParams["sortDir"]
  ) => {
    setCurrentPage(1);
    setSort({ sortColumn: column, sortDirection: direction });
  };

  const handleRowsPerPageChange = (newPerPage: number) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  const handleRowClick = (row: Course) => {
    if (!row?.id) return;
    navigate(MGRoutes.golfCourses_details.replace(":id", row.id.toString()));
  };

  if (!isAdmin) return <Navigate to={MGRoutes.notFound} />;

  return (
    <AuthenticatedPageTemplate
      titleProps={{
        breadcrumbsProps: [
          { title: "Dashboard", to: MGRoutes.dashboard },
          { title: "Golf Courses", active: true },
        ],
        title: "Golf Courses",
      }}
      loading={
        coursesFilterApi.isFetching ||
        coursesCreateApiMutation.isPending ||
        coursesDeleteApiMutation.isPending
      }
    >
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** DATA TABLE **** */}
                <DataTable<Course>
                  buttons={
                    <Button
                      variant={Variant.secondary}
                      // navigate={MGRoutes.golfCourses_create}
                      onClick={() => openCreateCourseModal()}
                    >
                      Add
                    </Button>
                  }
                  className="dataTable table-no-more"
                  // dense
                  columns={columns}
                  //   conditionalRowStyles={conditionalRowStyles}
                  data={courses?.items || []}
                  EmptyDataComponent={<EmptyDataBox />}
                  onSearch={setSearchValue}
                  onSort={handleSort}
                  paginate
                  search
                  searchValue={searchValue}
                  sortColumn={sort?.sortColumn}
                  sortDirection={sort?.sortDirection}
                  paginationProps={
                    !!paginationData && {
                      from: paginationData.from,
                      lastPage: paginationData.lastPage,
                      page: currentPage,
                      pageSize: pageSize,
                      total: paginationData.total,
                      to: paginationData.to,
                      onPageChange: setCurrentPage,
                      onPageSizeChange: handleRowsPerPageChange,
                    }
                  }
                  onRowClick={handleRowClick}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </AuthenticatedPageTemplate>
  );

  async function handleCreateCourse(data: Course): Promise<boolean> {
    try {
      await coursesCreateApiMutation.mutateAsync(data);
      return true; // this tells the form to reset
    } catch (err) {
      return false;
    }
  }

  async function handleEditCourse(data: Course) {
    try {
      await coursesUpdateApiMutation.mutateAsync(data);
      return true; // this tells the form to reset
    } catch (err) {
      return false;
    }
  }

  async function deleteCourse(id: number) {
    try {
      await coursesDeleteApiMutation.mutateAsync({ id });
      return true;
    } catch (err) {
      return false;
    }
  }

  function submitCourse() {
    golfCourseFormRef.current?.handleSubmit();
  }
};
