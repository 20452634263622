import { Variant } from "@stesvis/react-core";
import { FaCheck } from "react-icons/fa6";

import { Widget } from "[root]/src/components/molecules";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useMembershipApi } from "./useMembershipApi";

type PaymentsSuccessPageProps = {};

export const MembershipSuccessPage = (props: PaymentsSuccessPageProps) => {
  const [params] = useSearchParams();
  const sessionId = params.get("sessionId");

  const { membershipConfirmApiMutation } = useMembershipApi();

  useEffect(() => {
    if (sessionId) {
      membershipConfirmApiMutation.mutateAsync({
        checkoutSessionId: sessionId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  if (true)
    return (
      <Widget
        title="Payment successful! 🎉"
        IconComponent={FaCheck}
        variant={Variant.success}
      />
    );
};
