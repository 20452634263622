import { IconType } from "react-icons";

import { ComponentBaseProps } from "[root]/src/lib";
import clsx from "clsx";

type WidgetProps = Pick<ComponentBaseProps, "children" | "variant"> & {
  title: string;
  IconComponent?: IconType;
};

export const Widget = ({
  title,
  IconComponent,
  children: content,
  variant,
  ...props
}: WidgetProps) => {
  return (
    <section className="card mt-4">
      {IconComponent && (
        <header className="card-header bg-white">
          <div
            className={clsx(
              "card-header-icon",
              "d-flex align-items-center justify-content-center",
              variant && `bg-${variant}`
            )}
          >
            <IconComponent />
          </div>
        </header>
      )}
      <div className="card-body">
        <h3 className="mt-0 font-weight-semibold text-center">{title}</h3>
        <p className="text-center">{content}</p>
      </div>
    </section>
  );
};
