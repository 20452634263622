export const MGRoutes = {
  login: "/login",
  register: "/register",

  dashboard: "/",

  // golf courses
  golfCourses: "/golf-courses",
  golfCourses_create: "/golf-courses/create",
  golfCourses_details: "/golf-courses/:id",

  // membership
  membership: "/membership",
  membership_subscribe: "/membership/subscribe",
  membership_success: "/membership/success",

  // errors
  notFound: "/not-found",
};
