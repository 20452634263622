import clsx from "clsx";
import { Link } from "react-router-dom";

import logo from "[root]/src/assets/images/golfgt.png";
import { Overlay } from "[root]/src/components/atoms";
import { ComponentBaseProps } from "[root]/src/lib";

type AuthPageTemplateProps = Pick<
  ComponentBaseProps,
  "children" | "className"
> & {
  title: string;
  loading?: boolean;
};

export const AuthPageTemplate = ({
  children,
  className,
  title,
  loading,
  ...props
}: AuthPageTemplateProps) => {
  return (
    <>
      {loading && <Overlay />}

      <section className={clsx("body-sign", className)}>
        <div className="center-sign">
          <Link to="/" className="logo float-left">
            <img src={logo} height="70" alt="GolfGT" />
          </Link>
          <div className="panel card-sign">
            <div className="card-title-sign mt-3 text-end">
              <h2 className="title text-uppercase font-weight-bold m-0">
                <i className="bx bx-user-circle me-1 text-6 position-relative top-5" />
                {title}
              </h2>
            </div>
            <div className="card-body">{children}</div>
          </div>
        </div>
      </section>
    </>
  );
};
