import { Navigate, RouteProps, useSearchParams } from "react-router-dom";

import { Endpoints } from "@stesvis/metagolf-apis";
import { useAuthContext } from "[root]/src/contexts";
import { MGRoutes } from "[root]/src/lib";
import { useEffect } from "react";
import { Overlay, useAccountApi } from "../components";
import { useServices } from "../services";

type RequireAuthProps = RouteProps & {
  children: JSX.Element;
  redirectTo?: string;
};

export const RequireAuth = ({
  children,
  redirectTo = MGRoutes.login,
}: RequireAuthProps): JSX.Element | null => {
  const { session, loading, saveSession, saveCurrentUser } = useAuthContext();
  const services = useServices();
  const [params] = useSearchParams();
  const token = params.get("token");
  const refreshToken = params.get("refreshToken");

  const { userInfoApi } = useAccountApi();

  useEffect(() => {
    if (token && refreshToken) {
      saveSession({ token, refreshToken });
      services.utilities.invalidateQuery([Endpoints.Account.userInfo]);
      services.utilities.invalidateQueries([Endpoints.Account.userInfo]);
      // setTimeout(() => {
      userInfoApi
        .refetch()
        .then((result) => saveCurrentUser(result.data?.data));
      // }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, refreshToken]);

  // Show nothing or a loading indicator while the session is being loaded
  if (loading) {
    return <Overlay />;
  }

  const isAuthenticated = !!session?.token;

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={redirectTo} replace={false} />
  );
};
