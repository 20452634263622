import { Payment } from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";

import { DropdownButton } from "[root]/src/components/molecules";
import { useModalContext } from "[root]/src/contexts";
import { useServices } from "[root]/src/services";

type PaymentDataTableProps = {
  submitCancelPaymentCallback: (id: number) => Promise<boolean>;
};

export const usePaymentsDataTable = ({
  submitCancelPaymentCallback,
}: PaymentDataTableProps) => {
  const { showModal, hideModal } = useModalContext();
  const services = useServices();

  const menuItems = (row: Payment) => [
    // Delete
    {
      className: "text-danger",
      onClick: () => {
        showModal({
          title: "Cancel Membership?",
          body: "Are you sure you want to can your membership?",
          buttonsProps: {
            type: "two-buttons",
            leftButtonProps: {
              title: "No",
              variant: Variant.tertiary,
              onClick: hideModal,
            },
            rightButtonProps: {
              title: "Yes",
              variant: Variant.error,
              onClick: async () => {
                row.id && submitCancelPaymentCallback(row.id);
                hideModal();
              },
            },
          },
        });
      },
      title: "Cancel",
    },
  ];

  const columns = [
    {
      header: {
        id: "id",
        text: "",
        sortable: false,
        className: "col-menu",
      },
      cell: {
        dataTitle: "id",
        render: (row: Payment) => (
          <DropdownButton
            title={<i className="fa fa-bars" />}
            fixedTitle
            items={menuItems(row)}
            itemLabelProperty="title"
            itemValueProperty="title"
            variant={Variant.link}
            className="w-auto"
          />
        ),
      },
    },
    {
      header: { id: "name", text: "Name", sortable: true },
      cell: {
        dataTitle: "name",
        render: (row: Payment) => row.name,
      },
    },
    {
      header: {
        id: "amount",
        text: "Amount",
        sortable: true,
      },
      cell: {
        dataTitle: "amount",
        render: (row: Payment) =>
          !!row.amount &&
          `${services.utilities.formatNumber(
            (row.amount || 0) / 100,
            2
          )} ${row.currency?.toUpperCase()}`,
      },
    },
    {
      header: {
        id: "subscriptionStartDate",
        text: "Start",
        sortable: false,
      },
      cell: {
        dataTitle: "start",
        render: (row: Payment) =>
          services.utilities.formatDate(row.subscriptionStartDate),
      },
    },
    {
      header: {
        id: "subscriptionEndDate",
        text: "Valid Until",
        sortable: false,
      },
      cell: {
        dataTitle: "end",
        render: (row: Payment) =>
          services.utilities.formatDate(row.subscriptionEndDate),
      },
    },
  ];

  return { columns, menuItems };
};
