import { useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Col, Row } from "react-bootstrap";

import { PricingWidget } from "[root]/src/components/molecules";
import { AuthenticatedPageTemplate } from "[root]/src/components/templates";
import { MGRoutes } from "[root]/src/lib";
import { useServices } from "[root]/src/services";
import { SubscriptionForm } from "./SubscriptionForm";
import { useMembershipApi } from "./useMembershipApi";
import { usePaymentsApi } from "./usePaymentsApi";

type MembershipCreatePageProps = {};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const appearance = {
  // theme: "night",
  variables: {
    fontFamily: "Sohne, system-ui, sans-serif",
    fontWeightNormal: "500",
    // border: "1px solid #2e353e",
    colorBackground: "#2e353e",
    colorPrimary: "#ffffff",
    accessibleColorOnColorPrimary: "#ffffff",
    colorText: "#eeeee",
    colorTextSecondary: "white",
    colorTextPlaceholder: "#ABB2BF",
    colorDanger: "#f70303",
    tabIconColor: "white",
    logoColor: "light",
  },
  rules: {
    ".Input": {
      backgroundColor: "#000000",
      border: "1px solid #282d36",
      borderRadius: "4px",
      color: "#eeeeee",
    },
    ".Input:focus": {
      borderColor: "transparent",
      boxShadow:
        "0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(223, 105, 26, 0.3)",
    },
    ".Label": {
      color: "#f6a821",
    },
    ".AccordionItem": {
      border: "none",
      color: "#c4d0dd",
    },
    ".Block": {
      border: "none",
      // color: "#c4d0dd",
    },
    // ".AccordionPanel": {
    //   color: "#c4d0dd",
    // },
  },
};

export const MembershipSubscribePage = (props: MembershipCreatePageProps) => {
  const [showPaymentElement, setShowPaymentElement] = useState(false);
  const services = useServices();

  const { membershipIntentApiMutation, membershipProductDetailsApiHook } =
    useMembershipApi();

  const membershipProductDetailsApi = membershipProductDetailsApiHook({
    include: "Price",
    enabled: true,
  });
  const productDetails = membershipProductDetailsApi.data?.data;

  const { paymentsActiveSubscriptionApiHook } = usePaymentsApi();
  const paymentsActiveSubscriptionApi = paymentsActiveSubscriptionApiHook({
    enabled: true,
  });
  const activeSubscription = paymentsActiveSubscriptionApi.data?.data;

  const handleCheckoutClick = async () => {
    await membershipIntentApiMutation.mutateAsync(undefined);
    setShowPaymentElement(true);
  };

  return (
    <AuthenticatedPageTemplate
      titleProps={{
        breadcrumbsProps: [
          { title: "Dashboard", to: MGRoutes.dashboard },
          { title: "Membership", to: MGRoutes.membership },
          {
            title: "Subscribe",
            active: true,
          },
        ],
        title: "Subscribe",
      }}
      loading={
        membershipIntentApiMutation.isPending ||
        membershipProductDetailsApi.isLoading
      }
    >
      {!showPaymentElement
        ? productDetails &&
          productDetails.price && (
            <PricingWidget
              title={productDetails.name}
              price={productDetails.price.unitAmountDecimal / 100}
              description={productDetails.description}
              hideCheckoutButton={!!activeSubscription}
              onCheckoutClick={
                !activeSubscription ? handleCheckoutClick : undefined
              }
            />
          )
        : membershipIntentApiMutation.data?.data?.clientSecret && (
            <Elements
              stripe={stripePromise}
              options={{
                // passing the client secret obtained from the server
                clientSecret:
                  membershipIntentApiMutation.data?.data?.clientSecret,
                appearance: appearance,
              }}
            >
              <Row>
                <Col xl={6}>
                  <SubscriptionForm
                    intentSecret={
                      membershipIntentApiMutation.data?.data?.clientSecret
                    }
                  />
                </Col>
              </Row>
            </Elements>
          )}
    </AuthenticatedPageTemplate>
  );
};
