import {
  CheckoutSession,
  ConfirmPaymentRequest,
  DataResponse,
  Endpoints,
  Intent,
  Payment,
  ProcessPaymentRequest,
  StripeProduct,
} from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";
import { useApiMutation, useApiQuery } from "[root]/src/hooks";
import { ApiBaseProps } from "[root]/src/lib";
import { useServices } from "[root]/src/services";

export const useMembershipApi = () => {
  const services = useServices();

  const membershipIntentApiMutation = useApiMutation<
    DataResponse<Intent>,
    Error,
    undefined
  >({
    mutationKey: [Endpoints.Membership.intent],
    mutationFn: () => services.api.membership.intent(),
    onSuccess: (data) => {},
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const membershipCheckoutSessionApiMutation = useApiMutation<
    DataResponse<CheckoutSession>,
    Error,
    undefined
  >({
    mutationKey: [Endpoints.Membership.session],
    mutationFn: () => services.api.membership.session(),
    onSuccess: (data) => {},
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const membershipConfirmApiMutation = useApiMutation<
    DataResponse<Payment>,
    Error,
    ConfirmPaymentRequest
  >({
    mutationKey: [Endpoints.Membership.confirm],
    mutationFn: (data) => services.api.membership.confirm(data),
    onSuccess: (data) => {},
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const membershipProcessApiMutation = useApiMutation<
    DataResponse<Payment>,
    Error,
    ProcessPaymentRequest
  >({
    mutationKey: [Endpoints.Membership.process],
    mutationFn: (data) => services.api.membership.process(data),
    onSuccess: (data) => {},
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  type MembershipProductDetailsApiProps = ApiBaseProps;

  const useMembershipProductDetailsApi = ({
    include,
    enabled,
  }: MembershipProductDetailsApiProps) =>
    useApiQuery({
      queryKey: [Endpoints.Membership.productDetails],
      queryFn: async (): Promise<DataResponse<StripeProduct>> =>
        await services.api.membership.productDetails(include),
      enabled: enabled,
    });

  return {
    membershipConfirmApiMutation,
    membershipIntentApiMutation,
    membershipCheckoutSessionApiMutation,
    membershipProcessApiMutation,
    membershipProductDetailsApiHook: useMembershipProductDetailsApi,
  };
};
